<template>
  <div>
    <a-modal
      v-model="visibleModal"
      class="mod"
      :title="isEdit ? 'Form Edit Counseling Report' : 'Form Create Counseling Report'"
      @cancel="toggleModal()"
      centered
      width='75%'
      :maskClosable="false"
    >
      <form-counseling-list :categories="listCategory" :newData="newData" @handle-change="handleChange" :isSubmit="isSubmit" @handle-validate="handleValidate" :fieldsForm="fieldsForm" />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModal()">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModal"
          @click="handleOkModal"
        >
          {{ isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="visibleModalDetail"
      class="mod"
      :title="'Detail Counseling Report' "
      @cancel="toggleModalDetail()"
      centered
      width='60%'
      :footer="null"
    >
      <detail-counseling :dataCounseling="detailCounseling" />
    </a-modal>
    <div class="d-flex align-items center">
      <div :class="isMobile ? 'w-100' : 'mr-3'">
        <a-select
          :class="isMobile ? 'w-100' : null"
          size="large"
          style="min-width: 200px;"
          placeholder="Select Category"
          @change="fetchDataTable"
          v-model="selectedCategory"
        >
          <a-select-option
            value="all"
            >All Category
          </a-select-option>
          <a-select-option
            v-for="item in listCategory"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div :class="isMobile ? 'w-100' : ''">
        <a-input style="min-width: 250px;" v-model="search" @change="fetchDataTable" size="large" placeholder="Search by student name..."></a-input>
      </div>
      <div class="ml-auto" v-if="user.role.includes('bk')">
        <a-button
          @click.prevent="toggleModal()"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create New Report</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="columns"
        :data-source="dataTable"
        :pagination="{ pageSize: 10, hideOnSinglePage: true, ...pagination }"
        @change="handleTableChange"
        :loading="loadingTable"
        bordered
      >
        <div slot="nullStr" slot-scope="value">{{value || '-'}}</div>
        <div slot="date" slot-scope="value">{{moment(value).format('DD-MM-YYYY')}}</div>
        <div slot="action" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModalDetail(record)"
            class="border mb-2"
            size="large"
            block
          >
            <a-icon type="eye" /> View Detail
          </a-button>
          <div class="d-flex flex-row" v-if="user.role.includes('bk')">
            <a-button
              @click.prevent="toggleModal(record)"
              class="text-warning border border-warning w-50 mr-1"
              size="large"
            >
              <a-icon type="edit" />
            </a-button>
            <a-button
              @click.prevent="handleDelete(record)"
              class="text-danger border border-danger w-50"
              size="large"
            >
              <a-icon type="delete" />
            </a-button>
          </div>
        </div>
        <span slot="status" slot-scope="value">
          <a-tag v-if="value === 'closed'" color="red">closed</a-tag>
          <a-tag v-else color="green">ongoing</a-tag>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
const FormCounselingList = () => import('@/views/Teacher/Counseling/CounselingList/Form.vue')
const DetailCounseling = () => import('@/views/Teacher/Counseling/CounselingList/Detail.vue')
const columns = [
  {
    title: 'Date',
    dataIndex: 'counseling_date',
    key: 'counseling_date',
    align: 'center',
    width: 125,
    scopedSlots: { customRender: 'date' },
  },
  {
    title: 'Student',
    dataIndex: 'studentName',
    key: 'studentName',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'nullStr' },
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
    width: 180,
    scopedSlots: { customRender: 'nullStr' },
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    align: 'center',
    scopedSlots: { customRender: 'nullStr' },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    width: 180,
  },
]

const defaultNewData = {
  title: null,
  id_kelas_tahun_ajaran: null,
  counseling_date: null,
  id_kategori_bk: null,
  type: 'private',
  concern_indicator: 1,
  concerns: '',
  analysis: '',
  solution: '',
  status: 'on_going',
}
export default {
  components: { FormCounselingList, DetailCounseling },
  data() {
    return {
      pagination: { current: 1 },
      dataTable: [],
      columns,
      loadingTable: false,
      visibleModal: false,
      visibleModalDetail: false,
      newData: { ...defaultNewData },
      loadingActionModal: false,
      isEdit: false,
      isSubmit: false,
      editRecord: {},
      selectedCategory: 'all',
      search: null,
      detailCounseling: {},
      listCategory: [],
      fieldsForm: {},
    }
  },
  methods: {
    moment,
    toggleModal(data) {
      this.visibleModal = !this.visibleModal
      if (!this.visibleModal) {
        this.newData = { ...defaultNewData }
        this.isEdit = false
        this.editRecord = {}
      }

      if (data) {
        this.isEdit = true
        const { title, id_kelas_tahun_ajaran: idKta, counseling_date: counselingDate, id_kategori_bk: idCat, type, concerns, analysis, solution, concern_indicator: concernIndicator = 1, status = 'on_going' } = data
        this.newData = { title, id_kelas_tahun_ajaran: idKta, counseling_date: moment(counselingDate), id_kategori_bk: idCat, type, concerns, analysis, solution, concern_indicator: concernIndicator, status }
        this.editRecord = data
      }
    },
    toggleModalDetail(data) {
      this.visibleModalDetail = !this.visibleModalDetail
      if (!this.visibleModalDetail) {
        this.detailCounseling = {}
      }

      if (data) {
        this.detailCounseling = data
      }
    },
    handleDelete(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to delete this report?</div>
        ),
        onOk: async () => {
          try {
            await this.$store.dispatch('counseling/DELETE_COUNSELING_LIST', { id: data.id })
            this.$notification.success({
              message: 'Success',
              description:
                'Counseling type is deleted.',
            })
          } catch (err) {
            console.log(err)
            this.$notification.error({
              message: 'Error.',
              description: 'Counseling type cannot be deleted.',
            })
          }
          this.fetchDataTable()
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
      })
    },
    handleChange(payload) {
      const { value, column } = payload
      this.newData[column] = value
    },
    handleOkModal() {
      this.isSubmit = true
    },
    handleValidate(payload) {
      if (payload) {
        this.loadingActionModal = true
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to { this.isEdit ? 'edit this' : 'add new'} report?</div>
          ),
          onOk: async () => {
            const status = this.isEdit ? 'edited' : 'added'
            try {
              const dispatchStatus = this.isEdit ? 'PUT' : 'POST'
              const payloadEdit = this.isEdit ? { id: this.editRecord.id } : {}
              await this.$store.dispatch(`counseling/${dispatchStatus}_COUNSELING_LIST`, { newData: { ...this.newData }, ...payloadEdit })
              this.$notification.success({
                message: 'Success',
                description:
                  `Counseling type is ${status}.`,
              })
            } catch (err) {
              console.log(err)
              this.$notification.error({
                message: 'Error.',
                description: `Counseling type cannot be ${status}.`,
              })
            }
            this.fetchDataTable()
            this.toggleModal()
            this.isSubmit = false
            this.loadingActionModal = false
          },
          onCancel: () => {
            this.isSubmit = false
            this.loadingActionModal = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.isEdit ? 'Edit' : 'Add',
        })
      } else {
        this.$notification.error({
          message: 'Error.',
          description: 'All field is required.',
        })
        this.isSubmit = false
      }
    },
    async fetchDataTable() {
      try {
        this.loadingTable = true
        const { data = [], total } = await this.$store.dispatch('counseling/FETCH_COUNSELING_LIST', { page: this.pagination.current, search: this.search, id_kategori_bk: this.selectedCategory === 'all' ? null : this.selectedCategory })
        this.dataTable = data.map(dat => ({ ...dat, key: dat.id, category: dat.kategori_bk?.name, studentName: dat.kelas_tahun_ajaran?.murid?.nama }))
        this.pagination = { ...this.pagination, total }
        this.loadingTable = false
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchKategori() {
      try {
        const { data = [] } = await this.$store.dispatch('counseling/FETCH_COUNSELING_TYPE')
        this.listCategory = data.map(dat => { return dat })
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchFieldsForm() {
      try {
        const data = await this.$store.dispatch('counseling/FETCH_COUNSELING_LIST_FIELDS')
        this.fieldsForm = data
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      // let order = 'ASC'
      // let sortBy = ''
      pager.current = pagination.current
      this.pagination = pager
      // if (sorter) {
      //   sortBy = sorter.field
      //   // console.log(sorter)
      //   if (sorter.order === 'ascend') {
      //     order = 'ASC'
      //   } else if (sorter.order === 'descend') {
      //     order = 'DESC'
      //   }
      // }
      this.fetchDataTable()
    },
  },
  created() {
    this.fetchKategori()
    this.fetchDataTable()
    this.fetchFieldsForm()
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    user() { return this.$store.state.user.user },
  },
}
</script>

<style>
</style>
